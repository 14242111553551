<template>
  <div>
    <div v-if="error" class="alert alert-warning" role="alert">
      {{ this.error }}
    </div>
    <UserLoginForm v-if="!this.autoLogin" @onUserLoginClick="authunticateUser" />
  </div>
</template>
<script>
/* eslint-disable */
import { firebaseAuth } from "@/main.js";
import { firebaseApp } from "@/main.js";
import UserLoginForm from "@/components/UserLoginForm.vue";
import { mapGetters } from "vuex";
export default {
  name: "LoginUser",
  data() {
    return {
      error: "",
      autoLogin:false,
      userCredentails: { password: "", username: "" },
    };
  },
  created() {
    this.userCredentails = JSON.parse(localStorage.getItem("uc"));
    if (this.userCredentails) {
      this.autoLogin = true; 
      this.authunticateUser(this.userCredentails);
    }
  },
  components: {
    UserLoginForm,
  },
  methods: {
    authunticateUser(userCredentails) {
      if (!userCredentails.password || !userCredentails.username) {
        this.error = "Invalid user or password";
        return;
      }
      firebaseAuth
        .signInWithEmailAndPassword(
          userCredentails.username,
          userCredentails.password
        )
        .then((data) => {
          this.$router.replace({ name: "home" }).catch((err) => {
            console.warn(err);
          });
        })
        .catch((err) => {
          this.error = err.message.replace("Firebase: ", "");
        });
    },
  },
};
</script>