//Implementation of vuex statemanagment store
//This is where we will maintain state in the application
//for example the current user , using this will allow us to watch
//the state for certain object globaly 

import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
export default new Vuex.Store({
    state: {

        //Stores the current user object from firebase 
        //as user model - adding a getter to any object with in the 
        //applection will give access to this property of the store
        user: {
            loggedIn: false,
            data: null
        }
    },
    getters: { //gives us access to the getter 
        user(state) {
            return state.user
        }
    },
    mutations: { //allows mutatuion of the values
        SET_LOGGED_IN(state, value) {
            state.user.loggedIn = value;
        },
        SET_USER(state, data) {
            state.user.data = data;
        }
    },
    actions: {
        
        fetchUser({ commit }, user) {
            commit("SET_LOGGED_IN", user !== null);
            if (user)
                commit("SET_USER", { displayName: user.displayName, email: user.email });
            else commit("SET_USER", null);
        }
    }
});